.first-connection-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  .ant-tabs {
    max-width: 550px;
    width: 80vw;
  }
  .ant-alert-info {
    font-size: 0.75rem;
    hyphens: auto;
    margin-bottom: 28px;
    margin-top: 20px;
    text-align: justify;
  }
  .ant-form {
    & > .ant-row:first-child {
      margin-top: 16px;
    }
  }
  .half {
    display: flex;
    justify-content: space-between;
    .ant-legacy-form-item {
      flex-grow: 1;
      &:first-child {
        margin-right: 16px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .ant-card-body {
      padding: 8px;
    }
    .ant-tabs {
      width: 90vw;
    }
    .ant-tabs-tab {
      font-size: 12px;
    }
    .ant-alert-info {
      margin-bottom: 12px;
      margin-top: 12px;
    }
  }
}

.upload-container {
  align-items: center;
  display: flex;
  height: 75vh;
  justify-content: center;
  text-align: center;
  .ant-card {
    &:first-child {
      margin-right: 8px;
    }
  }
}

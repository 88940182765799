.upload-buttons {
  display: flex;
  justify-content: flex-end;
  .upload-btn {
    margin-left: 16px;
  }
}
.upload-list {
  background-color: white;
  margin: 0 auto 32px auto;
  min-width: calc(min(100%, 650px));
  width: 50%;
  .ant-list-header div {
    align-items: center;
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
    }
  }
  .ant-btn-link {
    padding-left: 0;
    width: calc(100% - 46px);
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      text-align: left;
    }
  }
}

$primary: #57368c;
$secondary: #323031;

@mixin title {
  font-family: 'changeling-neo', sans-serif;
  font-weight: 500;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  vertical-align: middle;
}

body {
  margin: 0px;

  .ant-drawer-content-wrapper {
    min-width: 256px;
    width: 20% !important;
  }

  .ant-btn {
    .anticon {
      margin-left: 0;
      &.anticon-loading {
        margin-right: 8px;
      }
    }
    &.responsive {
      font-size: calc(0.6vh + 9px);
    }
  }

  .ant-modal-confirm-content {
    color: #e41f18 !important;
  }

  .ant-input-number {
    width: 100% !important;
  }

  .ant-select-dropdown {
    img {
      margin-right: 2px;
      position: relative;
      text-overflow: initial;
      top: -1px;
      width: 20px;
    }
  }

  .ant-select {
    min-width: 100%;
    img {
      width: 25px;
    }
  }

  .upload-btn {
    .anticon {
      margin-left: 4px;
    }
  }

  .ant-menu-submenu .ant-menu .ant-menu-item .ant-menu-title-content .anticon {
    margin-right: 8px;
  }

  .Layout-container.ant-layout {
    min-height: 100vh;
    .header {
      background-color: $secondary;
      display: flex;
      justify-content: space-between;
      padding: 0 44px;
      &.red {
        background-color: lightcoral;
      }
      .main_title {
        @include title;
        color: white;
        font-size: 28px;
        margin: auto;
      }
      @media screen and (max-width: 992px) {
        .main_title {
          display: none;
        }
      }
      .ant-menu {
        background-color: $secondary !important;
        .ant-menu-item {
          line-height: 56px;
          height: 100%;
          &.ant-menu-item-active {
            .anticon {
              color: $secondary;
            }
          }
          .anticon {
            color: white;
          }
        }
        .ant-menu-submenu {
          &.ant-menu-submenu-active {
            .anticon {
              color: $secondary;
            }
          }
          .anticon {
            color: white;
          }
          &:hover {
            background-color: white;
          }
        }
      }
      .navigator {
        border: none;
        color: white;
        display: flex;
        flex-direction: column;
        .ant-menu-item:hover {
          background-color: white;
        }
      }
    }
    .content {
      padding: 44px;
      position: relative;
      .ant-input-prefix {
        color: rgba(0, 0, 0, 0.32);
      }
      a {
        font-weight: 500;
      }
    }
    @media screen and (max-width: 992px) {
      .content {
        padding: 8px;
      }
      .ant-legacy-form-item {
        margin-bottom: 12px;
      }
    }
    .footer {
      background-color: $secondary;
      color: white;
      display: flex;
      white-space: nowrap;
      .cycle {
        text-align: center;
        width: 100%;
        span:not(:last-child) {
          border-right: 1px solid white;
          margin-right: 8px;
          padding-right: 8px;
        }
      }
      a {
        color: white;
        float: right;
        margin-left: 16px;
      }
    }
  }
}

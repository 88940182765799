.signin-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100% - 138px);
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  .logo {
    margin-bottom: 2.5%;
    width: calc(25vh + 10vw);
  }
  @media screen and (max-height: 700px) {
    .logo {
      display: none;
    }
  }
  a {
    display: block;
    margin-top: 16px;
  }
  .ant-row {
    margin-bottom: 16px;
  }
  .help {
    margin-top: 16px;
  }
  .login-form-button {
    font-family: 'changeling-neo', sans-serif;
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }
}

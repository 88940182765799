.manage-buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: calc(50vh);

  & > button {
    margin-top: 30px;
  }
}

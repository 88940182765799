.ant-table-container {
  border: 2px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  .ant-table-body {
    max-height: calc(100vh - 329px) !important;
    z-index: 0;
    tr {
      height: 60px;
      td {
        padding-left: 12px !important;
        padding-right: 12px !important;
        .ant-row {
          height: 35px;
          left: -8px;
          margin: 0;
          position: relative;
          top: 1px;
          .ant-legacy-form-item-control {
            line-height: normal;
            &.has-error {
              .ant-legacy-form-explain {
                background-color: white;
                font-size: 13px;
                margin-top: 1px;
                padding: 1px 2px;
                position: fixed;
              }
            }
            .ant-select {
              min-width: 100%;
            }
            input {
              padding: 4px 8px;
            }
          }
        }
        img {
          object-fit: contain;
          max-height: 35px;
          width: 100%;
          &.country {
            width: 25px;
          }
        }
        &:not(:first-child) .ant-form-item {
          left: -12px;
        }
        .actionButtons {
          align-items: center;
          display: flex;
          margin: auto;
          width: min-content;
          & > span {
            display: flex;
            .validate-button,
            .cancel-button {
              cursor: pointer;
              font-size: 34px;
              &:hover {
                opacity: 0.75;
              }
            }
            .validate-button {
              margin-right: 4px;
            }
          }
          button {
            margin: 0 6px;
          }
        }
      }
    }
  }
}

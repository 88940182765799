div {
  &.max {
    font-style: italic;
    position: absolute;
    top: 14px;
  }
  h4 {
    border-bottom: 1px solid grey;
    margin-bottom: 16px !important;
  }
}

.form-buttons {
  border-top: 1px solid #e9e9e9;
  bottom: 0;
  right: 0;
  padding: 10px;
  position: absolute;
  text-align: right;
  width: 100%;
}
.actionsContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  .ant-btn:not(:first-child) {
    margin-left: 24px;
  }
}

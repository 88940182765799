$primary: #57368c;
$secondary: #323031;

.root {
  align-items: center;
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
  position: relative;
  top: -24px;

  .page {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 1109px;
    padding: 24px;
    position: relative;
    width: 794px;

    * {
      font-family: sans-serif;
    }

    .bdg-logo {
      margin-bottom: 16px;
      width: 320px;
    }

    .address {
      margin-bottom: 16px;
      text-align: center;
      p {
        font-size: 12px;
        margin: 0;
      }
    }

    .info {
      font-style: italic;
    }

    h1 {
      color: $primary;
      font-family: 'changeling-neo', sans-serif;
      letter-spacing: 1.4px;
      text-transform: uppercase;
    }

    h4 {
      margin-bottom: 0;
      font-weight: bold;
    }

    .hidden {
      list-style-type: none;
    }

    td,
    th {
      color: $secondary;
      font-size: 12px;
      padding: 4px 12px 2px 12px;
      &[align='center'] {
        text-align: center;
      }
      &[align='right'] {
        text-align: right;
      }
    }

    th {
      background-color: $secondary;
      color: white;
    }

    .tableInfo {
      border: 2px solid $secondary;
    }

    th {
      border-top: 1px solid #fff;
    }

    td {
      border: 1px solid $secondary;
    }
  }

  .userSignature {
    align-items: center;
    border-radius: 2px;
    border: 1px solid $secondary;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin: 16px auto 0;
    padding: 8px;
    text-align: center;
    width: min-content;
    img {
      margin-top: 4px;
      max-height: 125px;
      max-width: 250px;
    }
    span {
      color: $secondary;
      text-decoration: underline;
    }
  }

  .legal-div {
    margin: 20px auto 0;
    .legals {
      color: $secondary;
      font-size: smaller;
      font-style: italic;
      hyphens: auto;
      margin-bottom: 8px;
      text-align: justify;
    }
    .legalsRespect {
      font-size: medium;
      font-weight: bold;
    }
  }

  .contact {
    bottom: 16px;
    position: absolute;
    text-align: center;
    width: 60%;
    img {
      display: block;
      margin: auto;
    }
  }

  .requirements {
    background-color: #fafafa;
    border-radius: 8px;
    padding: 17px 32px 6px 32px;
    p:last-child {
      margin-bottom: 0;
    }
  }
}
